import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { FacultiesSelectionListRestrictionFormDialogComponent } from './faculties-selection-list-restriction-form-dialog/faculties-selection-list-restriction-form-dialog.component';
import {
  FacultyItem,
  FacultyRestrictionAdd,
  FacultyRestrictionItem,
  FacultyRestrictionRemove,
} from '../types';
import { FacultyOption, FacultyRestrictionReset } from './types';
import { RestrictionResetAtIndex } from './faculties-selection-list-item/types';

@Component({
  selector: 'app-faculties-selection-list',
  templateUrl: './faculties-selection-list.component.html',
  styleUrl: './faculties-selection-list.component.scss',
})
export class FacultiesSelectionListComponent {
  @Input() control: FormArray<FacultyItem>;
  @Input() facultyOptions: FacultyOption[];
  @Output() addFacultyRestriction = new EventEmitter<FacultyRestrictionAdd>();
  @Output() removeFacultyRestriction =
    new EventEmitter<FacultyRestrictionRemove>();
  @Output() resetFacultyRestriction =
    new EventEmitter<FacultyRestrictionReset>();

  constructor(public restrictionFormDialog: MatDialog) {}

  getFacultyControl(option: FacultyOption): FacultyItem {
    const facultyIndex = this.control.value.findIndex(
      (faculty) => faculty.facultyId === option.value,
    );

    return facultyIndex === -1 ? null : this.control.at(facultyIndex);
  }

  toggleFaculty(option: FacultyOption) {
    const facultyIndex = this.control.value.findIndex(
      (faculty) => faculty.facultyId === option.value,
    );

    if (facultyIndex === -1) {
      const newFormGroup = new FormGroup({
        facultyId: new FormControl(option.value),
        message: new FormControl(option.label),
        restriction: new FormArray([]),
      });

      newFormGroup.markAsDirty();
      this.control.markAsDirty();
      this.control.push(newFormGroup);
    } else {
      this.control.markAsDirty();
      this.control.removeAt(facultyIndex);
    }
  }

  selectAll() {
    this.facultyOptions.forEach((facultyOption) => {
      const control = this.getFacultyControl(facultyOption);

      if (!control) {
        this.control.markAsDirty();
        this.control.push(
          new FormGroup({
            facultyId: new FormControl(facultyOption.value),
            message: new FormControl(facultyOption.label),
            restriction: new FormArray([]),
          }),
        );
      }
    });
  }

  addRestriction(facultyIndex: number) {
    this.addFacultyRestriction.emit({ facultyIndex });
  }

  openRestrictionForm() {
    this.restrictionFormDialog.open(
      FacultiesSelectionListRestrictionFormDialogComponent,
      {
        data: { addsRestrictions: this.addsMultipleRestrictions.bind(this) },
      },
    );
  }

  addsMultipleRestrictions(restrictions: FormArray<FacultyRestrictionItem>) {
    this.control.controls.forEach((faculty) => {
      restrictions.controls.forEach((restriction) => {
        const existRestriction = faculty.controls.restriction.value.some(
          (facultyRestriction) =>
            facultyRestriction.unit === restriction.value.unit &&
            facultyRestriction.value === restriction.value.value,
        );

        if (existRestriction) {
          return;
        }

        const facultyIndex = this.facultyOptions.findIndex(
          (facultyOption) =>
            facultyOption.value === faculty.controls.facultyId.value,
        );
        const { value, unit } = restriction.value;

        this.addFacultyRestriction.emit({
          facultyIndex,
          restriction: { value, unit },
        });
      });
    });
  }

  removeRestriction(restrictionIndex: number, facultyIndex: number) {
    this.removeFacultyRestriction.emit({ restrictionIndex, facultyIndex });
  }

  resetRestriction(
    { field, restrictionIndex }: RestrictionResetAtIndex,
    facultyIndex: number,
  ) {
    this.resetFacultyRestriction.emit({
      field,
      restrictionIndex,
      facultyIndex,
    });
  }
}
