import { Component, Inject } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { createRestrictionFormGroup } from '../../utils/restriction';
import { RestrictionFormArray } from './types';

@Component({
  selector: 'app-faculties-selection-list-restriction-form-dialog',
  templateUrl:
    './faculties-selection-list-restriction-form-dialog.component.html',
  styleUrl: './faculties-selection-list-restriction-form-dialog.component.scss',
})
export class FacultiesSelectionListRestrictionFormDialogComponent {
  newRestrictions = new FormArray([createRestrictionFormGroup()]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      addsRestrictions: (restrictions: RestrictionFormArray) => void;
    },
  ) {}

  addRestriction() {
    const newRestriction = createRestrictionFormGroup();

    this.newRestrictions.push(newRestriction);
  }

  handleRestrictions() {
    this.data.addsRestrictions(this.newRestrictions);
  }
}
