<mat-dialog-content>
  <app-input-group
    [formArray]="newRestrictions"
    (add)="addRestriction()"
    title="Restricciones"
  >
    @for (newRestriction of newRestrictions.controls; track $index) {
      <app-faculties-selection-list-restriction-form
        [restriction]="newRestriction"
      ></app-faculties-selection-list-restriction-form>
    }
  </app-input-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="true"
    [disabled]="newRestrictions.invalid"
    (click)="handleRestrictions()"
  >
    Añadir
  </button>
</mat-dialog-actions>
