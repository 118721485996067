import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FacultyItem } from '../../types';
import { FacultyOption } from '../types';
import { RESTRICTION_UNITS } from '../../../../../../constants/restriction';
import { RestrictionResetAtIndex } from './types';

@Component({
  selector: 'app-faculties-selection-list-item',
  templateUrl: './faculties-selection-list-item.component.html',
  styleUrl: './faculties-selection-list-item.component.scss',
})
export class FacultiesSelectionListItemComponent {
  @Input() control: FacultyItem;
  @Input() option: FacultyOption;
  @Input() disabled: boolean;
  @Output() toggle = new EventEmitter();
  @Output() addRestriction = new EventEmitter();
  @Output() removeRestrictionAtIndex = new EventEmitter<number>();
  @Output() resetRestrictionAtIndex =
    new EventEmitter<RestrictionResetAtIndex>();

  restrictionUnitOptions = RESTRICTION_UNITS.map(({ displayName, value }) => ({
    label: displayName,
    value,
  }));

  resetRestriction({ field }, restrictionIndex: number) {
    this.resetRestrictionAtIndex.emit({ field, restrictionIndex });
  }
}
