<div
  class="app-faculties-selection-list"
  [ngClass]="{
    dirty: control.dirty,
    disabled: control.disabled,
    invalid: control.invalid
  }"
>
  <h3 class="title">Facultades</h3>
  <div class="actions">
    <button
      mat-raised-button
      (click)="selectAll()"
      [disabled]="control.disabled"
    >
      Seleccionar todo
    </button>
    <button
      mat-raised-button
      (click)="openRestrictionForm()"
      [disabled]="control.disabled"
    >
      <mat-icon>note_add</mat-icon>
      Añadir restricciones
    </button>
    <span class="spacer"></span>
    <ng-content></ng-content>
  </div>
  <ul class="list">
    @for (option of facultyOptions; track $index) {
      <app-faculties-selection-list-item
        [control]="getFacultyControl(option)"
        [disabled]="control.disabled"
        [option]="option"
        (addRestriction)="addRestriction($index)"
        (removeRestrictionAtIndex)="removeRestriction($event, $index)"
        (resetRestrictionAtIndex)="resetRestriction($event, $index)"
        (toggle)="toggleFaculty(option)"
      ></app-faculties-selection-list-item>
    }
  </ul>
</div>
