import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AdministrationGroupFacultyRestriction } from '../../../../../types';
import { FacultyRestrictionItem } from '../types';
import { DEFAULT_RESTRICTION_UNIT } from '../faculties-selection-list/faculties-selection-list-item/constants';

export const createRestrictionFormGroup = (
  restriction?: AdministrationGroupFacultyRestriction,
): FacultyRestrictionItem => {
  return new FormGroup({
    value: new FormControl(restriction?.value || null, [
      Validators.required,
      Validators.min(0),
    ]),
    unit: new FormControl(
      restriction?.unit || DEFAULT_RESTRICTION_UNIT,
      Validators.required,
    ),
  });
};
