<div class="app-faculties-selection-list-restriction-form">
  <app-study-form-input
    [control]="restriction.controls.unit"
    [options]="restrictionUnitOptions"
    (undo)="resetRestriction.emit({ field: 'unit' })"
    label="Unidad"
    type="select"
  />
  <app-study-form-input
    class="value"
    label="Monto"
    type="number"
    [control]="restriction.controls.value"
    (undo)="resetRestriction.emit({ field: 'value' })"
  />
</div>
