import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FacultyRestrictionItem } from '../../types';
import { RestrictionReset } from './types';
import { RESTRICTION_UNITS } from '../../../../../../constants/restriction';

@Component({
  selector: 'app-faculties-selection-list-restriction-form',
  templateUrl: './faculties-selection-list-restriction-form.component.html',
  styleUrl: './faculties-selection-list-restriction-form.component.scss',
})
export class FacultiesSelectionListRestrictionFormComponent {
  @Input() restriction: FacultyRestrictionItem;
  @Output() resetRestriction = new EventEmitter<RestrictionReset>();

  restrictionUnitOptions = RESTRICTION_UNITS.map(({ displayName, value }) => ({
    label: displayName,
    value,
  }));
}
